<template>
    <van-list v-model="loading" :finished="finished" loading-text="努力加载中" @load="onLoad">
        <div v-for="item in list" :key="item.id" class="card" @click="onClick(item)">
            <div class="card-header">
                <span>{{ formatTime(item.updatedTime) }}</span>
                <span :class="{ red: getRiskName(item.lossRisk) == '取消风险' }">
                    {{ getRiskName(item.lossRisk) }}
                </span>
            </div>

            <div class="detail">{{ item.riskRemarks }}</div>

            <div class="card-footer">
                <span>{{ getStageName(item.stage) }}</span>
                <span>{{ item.operateUserName }}</span>
            </div>
        </div>
        <van-empty v-if="list.length == 0 && finished" />
    </van-list>
</template>

<script>
import dayjs from "dayjs";
import { getRiskList } from "@/api/hoss/risk";
export default {
    data() {
        return {
            loading: false,
            finished: false,

            list: [],
            page: 0,

            hossStage: [],
            riskDict: [],
        };
    },
    async created() {
        const { clientId } = this.$route.query;
        this.clientId = clientId;
        const { hossStage, risk } = await this.$getDict();
        this.hossStage = hossStage.children;
        this.riskDict = risk.children;
    },
    methods: {
        // 破冰记录列表
        async getList() {
            const { clientId, page } = this;
            const { value } = await getRiskList({ clientId, page });
            this.list.push(...value.list);
            this.finished = value.lastPage;
            this.loading = false;
        },
        onLoad() {
            this.page++;
            this.getList();
        },
        onClick({ id, lossRisk }) {
            if (lossRisk == 1) return;
            this.$router.push(`/hoss/setRisk?clientId=${this.$route.query.clientId}&id=${id}`);
        },
        formatTime(t) {
            return dayjs(t).format("YYYY-MM-DD HH:mm:ss");
        },
        getStageName(stage) {
            if ((!stage && stage != 0) || !this.hossStage?.length) return "";
            const stageItem = this.hossStage.find((item) => item.code == stage);
            return stageItem?.name || "";
        },
        getRiskName(stage) {
            if ((!stage && stage != 0) || !this.riskDict?.length) return "";
            const stageItem = this.riskDict.find((item) => item.code == stage);
            return stageItem?.name || "取消风险";
        },
    },
};
</script>

<style lang="less" scoped>
.van-list {
    margin: 10px;
    padding: 10px 15px;
    border-radius: 10px;
    background-color: var(--cardBackgroundColor);
}
.card {
    font-size: 12px;
    background-color: var(--blueCardBackgroundColor);
    padding: 10px;
    border-radius: 8px;
    & + & {
        margin-top: 10px;
    }
    .card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        span + span {
            color: var(--green);
        }
        .red {
            color: var(--red);
        }
    }
    .detail {
        color: var(--subTitleColor);
        line-height: 1.3em;
        height: 3.9em;
        margin: 5px 0;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
    .card-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: var(--mainColor);
    }
}

.loading-box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
    span {
        margin-right: 10px;
    }
}
.van-empty {
    width: 180px;
    height: 180px;
    margin: 0 auto;
}
</style>
